<template>
	<v-app-bar id="default-app-bar" app absolute class="v-bar--underline" color="transparent" clipped-right height="70" flat>
		<v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer"/>
		<default-drawer-toggle class="hidden-sm-and-down"/>
		<v-btn icon class="mr-4" @click="back">
			<v-icon>
				{{ $icons.left }}
			</v-icon>
		</v-btn>
		<v-toolbar-title class="font-weight-light text-h5" v-text="name"/>
		<v-spacer/>
		<v-progress-circular
			v-show="appLoading > 0"
			indeterminate
			:width="4"
			color="primary"
		></v-progress-circular>
		<v-spacer/>
<!--		<default-search class="hidden-sm-and-down"/>-->
<!--		<default-go-home/>-->
<!--		<default-notifications/>-->
		<div style="max-width: 200px;">
			<env-switch solo></env-switch>
		</div>
		<default-account/>
	</v-app-bar>
</template>

<script>
// Utilities
import {get, sync} from 'vuex-pathify'

export default {
	name: 'DefaultBar',

	components: {
		// EnvSwitch: () => import(/* webpackChunkName: "default-account" */'./widgets/EnvSwitch'),
		DefaultAccount: () => import(/* webpackChunkName: "default-account" */'./widgets/Account'),
		DefaultDrawerToggle: () => import(/* webpackChunkName: "default-drawer-toggle" */'./widgets/DrawerToggle'),
		DefaultGoHome: () => import(/* webpackChunkName: "default-go-home" */'./widgets/GoHome'),
		DefaultNotifications: () => import(/* webpackChunkName: "default-notifications" */'./widgets/Notifications'),
		DefaultSearch: () => import(/* webpackChunkName: "default-search" */'./widgets/Search'),
	},

	computed: {
		...sync('app', [
			'drawer',
			'mini',
		]),
		name: get('route/meta@pageTitle'),
	},
	methods:{
		back(){
			this.$router.back()
		}
	}
}
</script>
